import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Route, Link as RouterLink
} from "react-router-dom";
import {Link} from "@mui/material";
import {MailOutline} from "@mui/icons-material";

export default function NavBar(
  {toggleLeftSideDrawer, setShowBookingModal, showBookingModal}:
    {toggleLeftSideDrawer: any, setShowBookingModal: any, showBookingModal: any}) {
  return (
    <Box component="div" sx={{ flexGrow: 1 }} className="navBar" >
      <AppBar position="fixed" className='toolbar'>
        <Toolbar sx={{ flexDirection: { xs: 'column', md: 'row' } }}>

            <Typography className="toolbar__header" variant="h4" component="div" sx={{ flexGrow: 1 }} >
              {/*{process.env.REACT_APP_NAV_TITLE}*/}
              <RouterLink to={`/`} color="inherit">
                <p style={{padding: '0', margin: '0'}}>unegma<span style={{color:'#0288d1'}}>.</span>land</p>
              </RouterLink>
            </Typography>



          <Typography
            className="toolbar__links"
            variant="h4"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {/*<RouterLink className='toolbar__links-margin' to={`/api`} color="inherit">*/}
            {/*  Api*/}
            {/*</RouterLink>*/}


            {/*<RouterLink to={`/examples`} color="white">*/}
            {/*  Examples&nbsp;&nbsp;*/}
            {/*</RouterLink>*/}

            <Link href="mailto:hello@unegma.com" target="_blank" underline="none">
              <MailOutline />&nbsp;
            </Link>

          </Typography>
          {/*<Button color="error" variant="contained" onClick={() => {setShowBookingModal(!showBookingModal)}}>Book</Button>*/}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
