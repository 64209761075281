import React, {useState} from 'react';
import {
  Route, Routes
} from "react-router-dom";
import './App.scss';
import NavBar from "./components/NavBar";
import {Container, CssBaseline, Grid, Paper, styled, Link} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import Book from "@mui/icons-material/Book";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  CameraAltOutlined,
  ChevronLeft,
  ChevronRight,
  InfoOutlined,
  Menu,
  WebAsset,
  ZoomIn,
  ZoomOut
} from "@mui/icons-material";
import PhotoViewer from "./components/PhotoViewer";
import InfoModal from "./components/InfoModal";
import LeftSideDrawer from "./components/LeftSideDrawer";
import HomeScreen from "./components/HomeScreen";
import SpaceOne from "./components/SpaceOne";
import Space from "./components/Space";
import ExamplesScreen from "./components/ExamplesScreen";
// import BookingModal from "./components/BookingModal";

function App() {
  const [showImages, setShowImages] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleLeftSideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))
      {
        return;
      }
      setDrawerOpen(!drawerOpen);
  };

  return (
    <div className="App">
      <CssBaseline />

      <NavBar toggleLeftSideDrawer={toggleLeftSideDrawer} showBookingModal={showBookingModal} setShowBookingModal={setShowBookingModal} />

      {/*<InfoModal showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} />*/}
      {/*<BookingModal showBookingModal={showBookingModal} setShowBookingModal={setShowBookingModal} />*/}
      {/*<PhotoViewer showImages={showImages} />*/}

      <LeftSideDrawer
        drawerOpen={drawerOpen}
        toggleLeftSideDrawer={toggleLeftSideDrawer}
        setShowImages={setShowImages}
        setShowInfoModal={setShowInfoModal}
      />

      <Routes>
        <Route
          key={'home'}
          path="/"
          element={
            <HomeScreen toggleLeftSideDrawer={toggleLeftSideDrawer} />
          }
        />

        {/*<Route*/}
        {/*  key={'examples'}*/}
        {/*  path="/examples"*/}
        {/*  element={*/}
        {/*    <ExamplesScreen toggleLeftSideDrawer={toggleLeftSideDrawer}/>*/}
        {/*  }*/}
        {/*/>*/}

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      <div className={`buttons-container buttons-container--left`}>
        {/*<Menu className="pointer" style={{ color: "white", margin: "0 4px" }} onClick={(event) => {toggleLeftSideDrawer(event)}}/>*/}
      </div>

      <div className="buttons-container">
        {/*<InfoOutlined className="pointer" style={{ color: "white", margin: "0 4px" }} onClick={() => {setShowInfoModal(!showInfoModal)}}/>*/}

        {/*<div className="pointer" onClick={() => {setShowImages(!showImages)}}>*/}
        {/*  <CameraAltOutlined  style={{ color: "white", margin: "0 4px" }} />*/}
        {/*  { showImages && (*/}
        {/*    <ChevronRight style={{ color: "white", margin: "0 4px" }} />*/}
        {/*  )}*/}
        {/*  { !showImages && (*/}
        {/*    <ChevronLeft style={{ color: "white", margin: "0 4px" }} />*/}
        {/*  )}*/}
        {/*  </div>*/}
      </div>


      <div className="footer">

        <Container className="footer-container">
          <Box component="div" sx={{ flexGrow: 1 }}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    className="footer__text"
                  >
                    <Link className="footer__link" href="https://unegma.com/" target="_blank" underline="none">
                      <WebAsset />
                      &nbsp;Unegma
                    </Link>
                  </Typography>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <Link href="https://github.com/unegma" target="_blank" underline="none">*/}
              {/*    <Typography*/}
              {/*      variant="h5"*/}
              {/*      component="div"*/}
              {/*      sx={{ flexGrow: 1 }}*/}
              {/*      className="footer__text"*/}
              {/*    >*/}
              {/*      <GitHubIcon />*/}
              {/*      &nbsp;Github*/}
              {/*    </Typography>*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Link href="https://discord.gg/dzYS3JSwDP" target="_blank" underline="none">*/}
              {/*    <Typography*/}
              {/*      variant="h5"*/}
              {/*      component="div"*/}
              {/*      sx={{ flexGrow: 1 }}*/}
              {/*      className="footer__text"*/}
              {/*    >*/}
              {/*      <img className="footer__logo" src={discordLogo} />*/}

              {/*      &nbsp;Discord*/}
              {/*    </Typography>*/}
              {/*  </Link>*/}
              {/*</Grid>*/}

              <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    className="footer__text"
                  >
                    <Link className="footer__link" href="https://twitter.com/unegma" target="_blank" underline="none">
                      <TwitterIcon/>
                      &nbsp;Twitter
                    </Link>
                  </Typography>
              </Grid>

              <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    className="footer__text"
                  >
                    <Link className="footer__link" href="https://www.youtube.com/channel/UC_B-UUwH1zLHD7im62m_gzw" target="_blank" underline="none">
                    <YouTube/>
                    &nbsp;Youtube
                    </Link>
                  </Typography>
              </Grid>



              {/*<Grid item xs={12}>*/}
              {/*  <Link href="https://docs.rainprotocol.xyz" target="_blank" underline="none">*/}
              {/*    <Typography*/}
              {/*      variant="h5"*/}
              {/*      component="div"*/}
              {/*      sx={{ flexGrow: 1 }}*/}
              {/*      className="footer__text"*/}
              {/*    >*/}
              {/*      <MenuBook/>*/}

              {/*      &nbsp;Docs*/}
              {/*    </Typography>*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  className="footer__text"
                >
                  <span className="footer__copyright"><a href="https://unegma.com" target="_blank">Unegma<span
                    className="unegma-blue">.</span></a>&nbsp;&copy; {new Date().getFullYear()}</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Container>

      </div>

    </div>
  );
}

export default App;
