import React, {Suspense, useRef} from "react";
import Button from "@mui/material/Button";
import {Link, Typography} from "@mui/material";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {CameraShake, Environment, Html, Loader, OrbitControls, PerspectiveCamera} from "@react-three/drei";
import SpaceOne from "./SpaceOne";
import Cafe from "./Cafe";
import MiddleFloor from "./MiddleFloor";
import Fridao from "./Fridao";
import BoardroomSpace from "./BoardroomSpace";
import * as THREE from "three";
import {MailOutline} from "@mui/icons-material";

export default function HomeScreen({toggleLeftSideDrawer}: {toggleLeftSideDrawer: any}) {

  function Rig({ children }: any) {
    const ref = useRef(null);
    const vec = new THREE.Vector3()
    const { camera, mouse } = useThree()
    useFrame(() => {
      camera.position.lerp(vec.set(mouse.x * 2, 0, 3.5), 0.05)
    })
    return <group ref={ref}>{children}</group>
  }

  return (
    <>
      {/*<div className="home-screen-cover-text" >*/}
      {/*  /!*<div className="home-screen-button-container">*!/*/}
      {/*  /!*  <Button size='large' className="home-screen-button" variant='contained' onClick={(event:any) => {toggleLeftSideDrawer(event)}}>Enter</Button>*!/*/}
      {/*  /!*</div>*!/*/}

      {/*  <Typography color='black'>For Virtual Tours</Typography>*/}


      {/*</div>*/}

      {/*<div className="home-screen-cover" >*/}
      {/*  /!*<div className="home-screen-button-container">*!/*/}
      {/*  /!*  <Button size='large' className="home-screen-button" variant='contained' onClick={(event:any) => {toggleLeftSideDrawer(event)}}>Enter</Button>*!/*/}
      {/*  /!*</div>*!/*/}


      <div className="section section__three">

        <div className="half-section">
          <div className='home-page__text-section-three'>
            <p className='section-title'>Virtual Tours</p>
            <p className='section-description'>3D Scans are usually low in quality to speed up web loading times, and are designed to give prospective clients a general feel of dimensions.<br/><br/>
              3D scans can be combined with real photos of a venue space to up the quality, and show clients a more accurate view of spaces.</p>
            <Button variant='contained' color='info' href='mailto:hello@unegma.com'><MailOutline />&nbsp;Get a 3D Scan</Button>
          </div>
        </div>

        <div className="half-section">

          <div className="boxview">
            {/*<Loader />*/}

            <Canvas className='canvas-container' dpr={[1, 2]} camera={{ position: [0, 0, 15] }} gl={{ alpha: false }}>

              <color attach="background" args={['white']} />
              <ambientLight />
              <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} minPolarAngle={Math.PI/2} maxPolarAngle={Math.PI/2} />
              <Suspense fallback={<Html>loading...</Html>}>
                <Environment preset="apartment" background={false} />
                {/*<Cafe scale={2}/>*/}
              </Suspense>
            </Canvas>
          </div>
        </div>

      </div>

      <div className="section section__two">

        <div className='examples-page__text-section-two'>
          <p className='section-title'>Unegma.Place</p>
          <p className='section-description'>3D Virtual Tour creation, aimed at Events/Venue Managers for showing their spaces.</p>
          <Button variant='contained' color='info' href='mailto:hello@unegma.com'><MailOutline />&nbsp;Get in Contact</Button>
        </div>


        <Loader />

        <Canvas className='canvas-container' dpr={[1, 2]} camera={{ position: [0, 0, 15] }} gl={{ alpha: false }}>

          <color attach="background" args={['white']} />
          <ambientLight />
          <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} minPolarAngle={Math.PI/2} maxPolarAngle={Math.PI/2} />
          <Suspense fallback={null}>
            <Environment preset="apartment" background={false} />


            <Rig>
              {/*<Cafe scale={3}/>*/}
            </Rig>

            {/*<EffectComposer multisampling={5}>*/}
            {/*  <Bloom kernelSize={1} luminanceThreshold={0} luminanceSmoothing={0.1} intensity={0.1} />*/}
            {/*  <Bloom kernelSize={KernelSize.SMALL} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.1} />*/}
            {/*</EffectComposer>*/}

          </Suspense>
          <CameraShake yawFrequency={0.1} pitchFrequency={0.1} rollFrequency={0} />
        </Canvas>


      </div>

      {/*<div className="section section__three">*/}
      {/*  <div className='spaceshow'>*/}
      {/*    <Link className="external-link" href="https://arkcoworking.space" target="_blank" underline="none">*/}
      {/*      <SpaceOne cameraPosition={[4,4,4]} space={<Cafe />}/>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className='spaceshow'>*/}
      {/*    <Link className="external-link" href="https://blacks.unegma.place" target="_blank" underline="none">*/}
      {/*      <SpaceOne cameraPosition={[5,5,5]} space={<MiddleFloor />}/>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className='spaceshow'>*/}
      {/*    <Link className="external-link" href="https://thursdao.xyz" target="_blank" underline="none">*/}
      {/*      <SpaceOne cameraPosition={[7,7,7]} space={<BoardroomSpace />}/>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className='spaceshow'>*/}
      {/*    <Link className="external-link" href="https://fridao.xyz" target="_blank" underline="none">*/}
      {/*      <SpaceOne cameraPosition={[5,5,5]} space={<Fridao />}/>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="home-screen-image" style={{backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}/ark.jpeg)`}}/>*/}
    </>
  )
}
